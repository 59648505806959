import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import SignUpForm from "@components/signUpForm"

import { Heading1 } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-slight-transparency.svg"

const SignUpPage = ({ location }) => {
  const RECAPTCHA_KEY = '6LdL8csqAAAAAC7PyiXqW3d5cCCshA4nlwgBHXmA';

  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="Sign Up to Get XYWAV® Information for You and Your Staff | XYWAV HCP"
        description="Sign up for email updates about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution for resources to help you care for your narcolepsy patients and/or information on the availability of XYWAV for idiopathic hypersomnia. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
        <section tw="relative bg-alabaster-2 pt-[70px] pb-[60px] xl:(pt-[80px] pb-[180px])">
          <img
            src={logo}
            alt=""
            tw="absolute top-[95px] right-[-25px] w-[273px] h-[218px] xl:(w-[827px] h-[647px] top-[15px] right-[-50px])"
          />
          <Container
            addedStyles={tw`w-full pl-2 sm:(w-[calc(100% - (17.5px * 2))] pl-0)`}
          >
            <p tw="mb-2.5 uppercase font-semibold text-[12px] text-merlin tracking-[0.6px] xl:(text-base)">
              Sign up
            </p>
            <h1
              css={[
                Heading1,
                tw`w-[95%] max-w-[500px] xl:(w-[unset] max-w-[955px])`,
              ]}
            >
              <strong>Sign up to get helpful information</strong> for you and
              your staff
            </h1>
            <SignUpForm />
          </Container>
        </section>
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default SignUpPage

SignUpPage.propTypes = {
  location: PropTypes.object.isRequired,
}
